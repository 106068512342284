import { apiURL } from '../main'
import _ from 'lodash'
import { showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'

const pageingQuery = {
  sort: 'id',
  page: 0,
  size: 10
}
const searchQuery = { term: '' }

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BaseType',
  data() {
    return {
      name: 'BaseType',
      schema: {},
      //pageing: "server", // server | client
      query: {
        ...pageingQuery,
        ...searchQuery,
        ...this.$route.query
      },
      totalElements: 0,
      items: [], //process.env.NODE_ENV !== "production" ? buyerMilk.content : [],
      fields: <DTSHeader[]>[],
      isBusy: false,
      apiURL: apiURL + '/BaseType/findXYZ'
    }
  },
  methods: {
    search() {
      this.router(this.query)
    },
    /*
    router(qry) {
      if (JSON.stringify(qry) != JSON.stringify(this.$route.query)) {
        this.$router
          .push({
            path: this.$router.name,
            query: qry
          })
          .catch((e) => {
            //duplicated url
          })
      }
    },
    */
    async load(apiUrl: string | undefined) {
      this.isBusy = true
      if (!apiUrl) apiUrl = this.apiURL
      try {
        // #/de/products/:id
        // id == create ? file : axios ..
        const response = await this.axios.get(apiUrl, {
          params: this.query
        })
        this.isBusy = false
        const items = await response.data.content
        this.totalElements = this.checkPage(response.data.totalElements)
        this.items = Array.isArray(items) ? items : []
      } catch (e) {
        this.items = []
        showError(e)
      }
      return true
    },
    filterOutProperties(array, filters) {
      _.filter(array, function (field) {
        return _.every(filters, function (filter) {
          return field.key.indexOf(filter) === -1
        })
      })
    },
    linkGen(page) {
      return {
        query: { ...this.query, ...{ page: page - 1 } }
      }
    },
    filterAPIFieldsX(jsonSchema) {
      delete jsonSchema.properties.id
      delete jsonSchema.properties.modification
      delete jsonSchema.properties.modifierId
      delete jsonSchema.properties.creation
      delete jsonSchema.properties.creatorId
      return jsonSchema
    }
  },
  watch: {
    $route() {
      this.query = {
        ...this.pageingQuery,
        ...this.searchQuery,
        ...this.$route.query
      }
      this.load()
    }
  },
  mounted() {
    // only load data when the urlquery contains information
    if (this.$route && this.$route.query.size && this.$route.params.id) {
      this.load()
    }
  }
})
